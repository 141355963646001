<template>
  <el-dialog
    data-cy="claim-cashback-modal"
    v-loading="isLoading"
    :visible="isOpen"
    title="Prise en compte de la réclamation"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="730px"
  >
    <el-row>
      <el-col :offset="4" :span="16" class="align-left">
        <el-form
          :model="inputs"
          ref="form"
          label-position="top"
          :rules="rules"
          @submit.native.prevent
        >
          <p class="message">
            Renseignez l’objet de la réclamation et attachez le justificatif:
          </p>

          <el-form-item data-cy="claim-message" prop="comment" label="" class="comment">
            <el-input
              type="textarea"
              :rows="5"
              v-model="inputs.comment">
            </el-input>
          </el-form-item>

        </el-form>

        <el-row type="flex" align="middle">
          <el-col class="flex-1">
            <button @click="event => $refs.inputFile.click(event)" class="upload-btn">Joindre justificatif</button>
          </el-col>
          <el-col>
            <p>{{ inputs.fileName }}</p>
          </el-col>
        </el-row>
        <p class="infos">{{ fileInfo }}</p>

        <input
          ref="inputFile"
          type="file"
          @change="updateFile($event)"
          :accept="fileAccept"
          class="hidden" />

      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button data-cy="cancel" @click="$emit('close')">Annuler</el-button>
      <el-button data-cy="submit" type="primary" @click="submit()">Enregistrer</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { createRequired } from '@/data/input-rules'
import UploadAttachedFile from '@/mixins/UploadAttachedFile.js'

export default {
  mixins: [
    UploadAttachedFile
  ],
  props: {
    clubistId: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      inputs: {
        comment: '',
        fileName: ''
      },
      rules: {
        comment: [
          createRequired('commentaire', { message: 'Veuillez renseigner les détails de la réclamation' })
        ]
      }
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true
          const category = 'CASHBACK_COMPLAIN'
          const payload = {
            clubistId: this.clubistId
          }

          payload.comment = this.inputs.comment
          let promise
          if (this.file) {
            promise = this.uploadFile(category)
              .then(fileData => {
                payload.file = fileData
                return this.$store.dispatch('cashbackComplaint', payload)
              })
          } else {
            promise = this.$store.dispatch('cashbackComplaint', payload)
          }

          promise
            .then(() => {
              this.$message({
                message: 'Demande d’alimentation de la jauge effectuée',
                type: 'success'
              })
            })
            .then(() => this.$emit('submit'))
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
              this.$emit('close')
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}
.message {
  font-size: 16px;
}
.infos {
  font-size: 12px;
  opacity: 0.5;
}
.align-left {
  text-align: left;
}
.flex-1 {
  flex: 1;
}
.upload-btn {
  background: $blue;
  color: #FFF;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  padding: 0.5em 1.9em;
  margin: 0.8em 0.8em 0.6em 0;
  white-space: nowrap;
}
.comment {
  ::v-deep {
    .el-form-item__content {
      max-height: none;
    }
  }
}
</style>
