<template>
  <el-dialog
    v-loading="isLoading"
    :visible="isOpen"
    title="Désactiver la carte Club"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="730px"
  >
    <el-row>
      <el-col :offset="4" :span="16" >
        <el-form
          :model="inputs"
          ref="form"
          label-position="top"
          :rules="rules"
        >
          <el-alert
            title=""
            type="info"
            :closable="false"
            show-icon
            class="alert"
          >
            Si ce n’est pas déjà fait, merci d’indiquer au clubiste d’aller en station pour obtenir une nouvelle carte Club et de rappeler le CCC afin de désactiver l’ancienne carte et renseigner la nouvelle.
          </el-alert>
          <el-row>
            <el-col :offset="2" :span="18" >
              <el-form-item prop="cardNum" label="N° de carte Club">
                <el-input minlength="19" maxlength="19" show-word-limit v-model="inputs.cardNum"></el-input>
              </el-form-item>
              <p>
                Le transfert du cashback est bien pris en compte.
              </p>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">Annuler</el-button>
      <el-button type="primary" @click="remove()">Confirmer</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { clubCardNumRule, createRequired } from '@/data/input-rules'

export default {
  props: {
    clubistId: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      inputs: {
        cardNum: ''
      },
      rules: {
        cardNum: [
          ...clubCardNumRule,
          createRequired('numéro de carte')
        ]
      }
    }
  },
  methods: {
    remove () {
      this.isLoading = true
      const clubist = {
        id: this.clubistId,
        cardNum: this.inputs.cardNum
      }

      this.$store.dispatch('editClubist', clubist)
        .then(() => this.$message({
          message: 'La demande de suppression de Carte Club a bien été effectuée',
          type: 'success'
        }))
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isLoading = false
          this.$emit('submit')
          this.$emit('close')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  text-align: left;
  ::v-deep {
    .el-alert__description {
      margin-bottom: 0;
    }
  }
}
.el-form-item {
  text-align: left;
}
</style>
