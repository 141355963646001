<template>
  <section class="section-clubistes">
    <el-menu class="menu-left" :default-active="$route.name" @select="fixFastClick">
      <el-menu-item v-if="displayMenu" index="clubistProfile" class="first-el-menu-item">
        <router-link data-cy="menu-clubist-info-cta" :to="{ name: 'clubistProfile', params: { clubistId } }" class="link">
          Informations
        </router-link>
      </el-menu-item>

      <el-menu-item v-if="displayMenu" index="clubistCashback" >
        <router-link data-cy="menu-clubist-cashback-cta" :to="{ name: 'clubistCashback', params: { clubistId } }" class="link">
          Cashback
        </router-link>
      </el-menu-item>

      <el-menu-item data-cy="menu-clubist-history-cta" v-if="displayMenu" index="clubistHistory">
        <router-link :to="{ name: 'clubistHistory', params: { clubistId } }" class="link">
          Historique
        </router-link>
      </el-menu-item>

      <el-menu-item v-if="displayMenu && $store.getters.isMarketing" index="clubistTodo">
        <router-link data-cy="todo-cta" :to="{ name: 'clubistTodo', params: { clubistId } }" class="link">
          A faire <round-badge :value="allRequests.length"></round-badge>
        </router-link>
      </el-menu-item>
    </el-menu>

    <div class="slot">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ClubistBody',

  props: {
    clubistId: {
      type: String,
      required: true
    },

    displayMenu: {
      type: Boolean,
      default: true
    },

    allRequests: {
      type: Array,
      default () { return [] }
    },

    cashbackComplainsRequests: {
      type: Array,
      default () { return [] }
    },

    statusUpdateRequests: {
      type: Array,
      default () { return [] }
    }
  },

  methods: {
    // If you click faster the route don't change
    // This fix add a test (from menu) to force route to change
    fixFastClick (name) {
      this.$nextTick(() => {
        if (name !== this.$route.name) {
          this.$router.push({ name, params: { clubistId: this.clubistId } })
            .catch(error => console.error(error.message))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}
.section-clubistes {
  display: flex;
  .slot {
    flex: 1;
  }
}
</style>
