<template>
  <el-dialog
    v-loading="isLoading"
    :visible="isOpen"
    title="Demande d’une nouvelle Card prépayée"
    :modalAppendToBody="false"
    :before-close="close"
    :center="true"
    width="730px"
  >
    <el-row>
      <el-col :offset="4" :span="16" >
        <TotalCardClaim ref="totalCardClaim" type="blockPrepaidCard" :clubistId="clubistId" @submit="$emit('submit')" @label-change="label => nextLabel = label" @close="close()"/>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button @click="close">Annuler</el-button>
      <el-button type="primary" @click="next()">Confirmer</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TotalCardClaim from '@/components/TotalCardClaim.vue'

export default {
  components: {
    TotalCardClaim
  },
  props: {
    clubistId: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      inputs: {
        hasTotalCard: false
      },
      nextLabel: 'Suivant'
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    next () {
      this.$refs.totalCardClaim.next()
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  text-align: left;
  ::v-deep {
    .el-alert__description {
      margin-bottom: 0;
    }
  }
}
</style>
