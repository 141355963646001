<template>
  <div>
    <div class="category-container">
      <div class="left">
        Sélectionner le type de données souhaité :
      </div>
      <div class="right">
        <el-radio-group v-model="category">
          <el-radio label="TRANSACTION">Transactions</el-radio>
          <el-radio label="GAUGE">Alimentation Jauge</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="search-container">
      <div class="left">
        <p class="select-period">Puis la période souhaitée à exporter :</p>
      </div>

      <div class="right">
        <p class="period">{{ $t('evenementialExports.search.period') }}</p>

        <DateRangeExport class="daterange" @dates="dates => selectedDates = dates" />

        <el-button type="danger" class="cta" @click="submit" :disabled="!hasSelectedDates">
          {{ $t('evenementialExports.search.cta') }}
        </el-button>
      </div>
    </div>

    <div class="table-container">
      <TableExport :is-loading="isLoading" :display-category="true" :exported="exported" />
    </div>
  </div>
</template>

<script>
import { frDateToStdDate } from '@/data/input-rules'
import AutoReload from '@/mixins/AutoReload'

import DateRangeExport from '@/components/export/DateRangeExport'
import TableExport from '@/components/export/TableExport'

export default {
  name: 'EvenementialExports',

  components: {
    DateRangeExport,
    TableExport
  },

  mixins: [AutoReload],

  props: {
    clubistId: {
      type: String
    }
  },

  data () {
    return {
      isLoading: false,
      selectedDates: null,
      exported: [],
      category: null
    }
  },

  computed: {
    hasSelectedDates () {
      return this.category && this.selectedDates && this.selectedDates[0] && this.selectedDates[1]
    }
  },

  created () {
    this.update()
  },

  methods: {
    onReload () {
      this.update()
    },

    update () {
      this.isLoading = true
      const categories = {
        TRANSACTION: 'Transactions',
        GAUGE: 'Alimentation Jauge'
      }

      this.$store.dispatch('exports/getMediaList', {
        category: ['TRANSACTION', 'GAUGE'],
        clubistId: this.clubistId
      })
        .then((medias) => {
          this.exported = medias
            .map(media => Object.assign({}, media, {
              categoryLabel: categories[media.category] || ''
            }))
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    submit () {
      this.isLoading = true

      const [from, to] = this.selectedDates

      this.$store.dispatch('exports/claimMedia', {
        startDate: frDateToStdDate(from),
        endDate: frDateToStdDate(to),
        category: this.category,
        clubistId: this.clubistId
      })
        .then(() => this.$message({
          message: `La demande d'export a bien été prise en compte.`,
          type: 'success'
        }))
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.search-container, .category-container {
  z-index: 1;
  display: flex;

  .left {
    display: flex;
    align-items: center;
    width: 38%;
  }

  .right {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
  }

  .period {
    margin: 0 24px 0 0;
  }

  .daterange {
    margin: 0 32px 0 0;
  }
}

.category-container {
  margin: 2.5em 0 1.5em;
}

.table-container {
  z-index: 0;
  margin: 24px 0 0;
}
</style>
