<template>
  <div v-loading="clubist.isLoading">
    <section class="section-container">
      <el-row type="flex" align="bottom" :gutter="100">
        <el-col>
          <DirhamCounter :cashback="clubist.cashback"></DirhamCounter>
        </el-col>
        <el-col>
          <h2>Solde de cashback</h2>
          <ul class="cashback-legend">
            <li class="cashback-legend-debloque">Totalité de cashback cumulé</li>
            <li class="cashback-legend-cumule">Cashback débloquable</li>
          </ul>

          <el-alert
            v-if="cashbackAlertDate"
            title=""
            type="warning"
            :closable="false"
            show-icon
            class="alert"
          >
            Expiration de {{ clubist.cashbackAlertAmount }} dirhams le {{ cashbackAlertDate }}
          </el-alert>
          <p v-else>
            Prochain palier:
            <strong>{{nextStep}} dhs</strong>
          </p>

        </el-col>
      </el-row>
    </section>
    <section>
      <el-row type="flex" :gutter="25">
        <el-col :span="12">
          <div v-loading="isClaimUnlockCashbackLoading" class="box col-container align-center">
            <h2>Débloquer du cashback</h2>
            <p>
              Cette action engendrera le crédit du solde sur la TotalEnergies card.
            </p>
            <el-button type="danger" @click="dialogUnlockVisible = true" :disabled="currentStep <= 0 || clubist.statusID > 0">Débloquer {{ currentStep }} dhs</el-button>

            <div v-if="clubist.isPrepaidCardClaimOnDelivery" class="during">
              <h2>Transfert en cours</h2>
              <p>
                Le transfert de {{ currentStep }} dhs sur la Card prépayée est en cours de traitement
              </p>
            </div>
            <div v-else-if="cashingAmount" class="during">
              <h2>Transfert en cours</h2>
              <p>
                Le transfert de {{ cashingAmount }} dhs sur la Card prépayée est en cours de traitement
              </p>
            </div>
            <div v-else-if="!clubist.prepaidCardNum" class="during">
              <h2>Aucune Card prépayée renseignée</h2>
              <p>Veuillez réaliser une demande de Card prépayée depuis la fiche du clubiste pour débloquer du cashback.</p>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="box col-container align-center">
            <h2>Ajouter du cashback</h2>
            <p>Cette action notifiera TotalEnergies Marketing qu’une réclamation d’ajout de cashback a été initiée par le Clubiste</p>
            <el-button data-cy="cashback-claim-cta" type="danger" :disabled="clubist.statusID > 0" @click="isModalCashbackComplaintOpen = true">Réclamation de cashback</el-button>

            <div v-if="cashbackComplainsRequests.length > 0" class="during">
              <h2>Alimentation en cours</h2>
              <p>
                La réclamation d’ajout de cashback va être envoyée à TotalEnergies Marketing pour validation.
              </p>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>

    <el-dialog
      title="Débloquer le cashback"
      :visible.sync="dialogUnlockVisible"
      width="540px"
      :before-close="handleClose"
      :modal-append-to-body="false"
      :center="true"
      :show-close="false"
    >
      <el-row type="flex" align="middle">
        <el-col :offset="2" :span="20">
          <p>
            Cette action requiert une vérification.<br>
            Veuillez vérifier le N° de Card prépayée ci-dessous.
          </p>
          <p>
            N° de Card prépayée:
            <strong>{{ clubist.prepaidCardNum }}</strong>
          </p>
          <p>La demande va être transmise aux équipes Marketing.</p>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" plain @click="dialogUnlockVisible = false">Annuler</el-button>
        <el-button type="danger" @click="dialogUnlockVisible = false; unlockCachback()">Confirmer</el-button>
      </span>
    </el-dialog>

    <ModalCashbackComplaint
      :clubistId="clubistId"
      :isOpen="isModalCashbackComplaintOpen"
      @close="isModalCashbackComplaintOpen = false"
      @submit="$emit('change')"
    />

  </div>
</template>

<script>
import DirhamCounter from '@/components/DirhamCounter.vue'
import ModalCashbackComplaint from '@/modals/CashbackComplaint.vue'

export default {
  name: 'ClubistCashback',
  components: {
    DirhamCounter,
    ModalCashbackComplaint
  },
  props: {
    clubistId: {
      type: String
    },
    clubist: {
      type: Object
    },
    cashbackComplainsRequests: {
      type: Array
    }
  },
  data () {
    return {
      dialogUnlockVisible: false,
      isClaimUnlockCashbackLoading: false,
      isModalCashbackComplaintOpen: false
    }
  },
  computed: {
    nextStep () {
      return Math.max(0, Math.floor(this.clubist.cashback / 50) * 50 + 50)
    },
    currentStep () {
      return Math.max(0, Math.floor(this.clubist.cashback / 50) * 50)
    },
    cashingAmount () {
      const canUnlock = !!(this.clubist && this.clubist.cashbackUnlockClaim && this.clubist.cashbackUnlockClaim.cashback)
      return canUnlock ? this.clubist.cashbackUnlockClaim.cashback : 0
    },
    cashbackAlertDate () {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return this.clubist.cashbackAlert ? (new Date(this.clubist.cashbackAlert)).toLocaleDateString('fr-FR', options) : null
    }
  },
  methods: {
    addCashback () {

    },
    unlockCachback () {
      const payload = {
        clubistId: this.clubistId
      }

      this.isClaimUnlockCashbackLoading = true
      return this.$store.dispatch('claimUnlockCashback', payload)
        .then(() => {
          this.$message({
            message: 'Demande de retrait de cashback effectuée',
            type: 'success'
          })
        })
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isClaimUnlockCashbackLoading = false
        })
    },
    handleClose () {

    }
  }
}
</script>

<style lang="scss" scoped>
.col-container {
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);
  border: 1px solid #e4e7ed;
  padding: 40px 80px;
  height: 100%;
  p {
    margin-bottom: 25px;
  }
}
.cashback-legend {
  margin-bottom: 25px;
  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-radius: 2px;
      margin-right: 15px;
    }
  }
}
.cashback-legend-debloque {
  &:before {
    background-color: $blue;
  }
}
.cashback-legend-cumule {
  &:before {
    background-color: #4A96D2;
  }
}
.box {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}
// Only during v0
.unlock-cashback-disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(mix($blue, #EEE, 40%), 0.95);
  }

  h2, p {
    position: relative;
    color: white;
  }
}
.alert {
  margin-top: 1em;
}
.during {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(mix($blue, #EEE, 40%), 0.95);
  }

  h2, p {
    position: relative;
    color: white;
  }
}
</style>
