<template>
  <div v-loading="isLoading" class="list">
    <template v-if="list.length > 0" class="list">
      <el-table :data="part" @sort-change="onSort">
        <el-table-column fixed width="140" prop="date" :label="$t('clubistHistory.tables.events.column.date')" sortable="custom">
          <template slot-scope="scope">
            {{ dateToDateTimeStr(scope.row.date) }}
          </template>
        </el-table-column>
        <el-table-column width="150" :label="$t('clubistHistory.tables.events.column.user')">
          <template slot-scope="scope">
            {{ scope.row.operator.firstName }} {{ scope.row.operator.lastName }}
          </template>
        </el-table-column>
        <el-table-column width="200" :label="$t('clubistHistory.tables.events.column.type')">
          <template slot-scope="scope">
            {{ $t(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column align="left" prop="detail" :label="$t('clubistHistory.tables.events.column.detail')">
          <template slot-scope="scope">
            {{ $t(scope.row.detail) }}
          </template>
        </el-table-column>
        <el-table-column width="50" align="left" label="">
          <template slot-scope="scope">
            <Information v-if="scope.row.extra.station" @click.native="modalStationData = scope.row.extra.station" />
            <Information v-else-if="scope.row.extra.media" @click.native="modalMediaData = scope.row.extra.media" />
            <Information v-else-if="scope.row.extra.clubCard" @click.native="modalClubCardData = scope.row.extra.clubCard" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage"
        :total="list.length"
        :page-size="displayCount"
        v-if="!isLoading"
        hide-on-single-page
        @current-change="num => currentPage = num"
        @prev-click="() => currentPage--"
        @next-click="() => currentPage++"
        class="pagination"
      ></el-pagination>
    </template>

    <p v-else-if="!isLoading" class="empty">
      - {{ $t('tables.empty') }} -
    </p>

    <el-dialog
      v-loading="isLoading"
      :visible="modalStationData !== null"
      title=""
      :center="false"
      :modalAppendToBody="false"
      :before-close="() => modalStationData = null"
      width="260px"
      class="modal"
    >
      <el-row>
        <el-col :offset="0" :span="24" v-if="modalStationData" >
          <p class="modal__title">Lieu de collecte</p>

          <p class="modal__content" v-if="modalStationData.name">
            Point relais
          </p>

          <p class="modal__content" v-else>
            Domicile
          </p>

          <p
            class="modal__title"
            v-if="modalStationData.name ||
              modalStationData.street ||
              modalStationData.complement ||
              modalStationData.district ||
              modalStationData.zipcode ||
              modalStationData.city"
          >
            Adresse
          </p>

          <p class="modal__content">
            <span v-if="modalStationData.name">{{ modalStationData.name }}<br></span>
            <span v-if="modalStationData.street">{{ modalStationData.street }}<br></span>
            <span v-if="modalStationData.complement">{{ modalStationData.complement }}<br></span>
            <span v-if="modalStationData.district">{{ modalStationData.district }}<br></span>
            <span v-if="modalStationData.zipcode">{{ modalStationData.zipcode }} </span>
            <span v-if="modalStationData.city">{{ modalStationData.city }}</span>
          </p>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      v-loading="isLoading"
      :visible="modalMediaData !== null"
      title=""
      :center="false"
      :modalAppendToBody="false"
      :before-close="() => modalMediaData = null"
      width="260px"
      class="modal"
    >
      <el-row>
        <el-col :offset="0" :span="24" v-if="modalMediaData" >
          <p class="modal__title">Période</p>

          <p class="modal__content">
            Du {{ dateToDateStr(modalMediaData.startDate) }} au {{ dateToDateStr(modalMediaData.endDate) }}
          </p>
        </el-col>
      </el-row>
    </el-dialog>

    <el-dialog
      v-loading="isLoading"
      :visible="modalClubCardData !== null"
      title=""
      :center="false"
      :modalAppendToBody="false"
      :before-close="() => modalClubCardData = null"
      width="260px"
      class="modal"
    >
      <el-row>
        <el-col :offset="0" :span="24" v-if="modalClubCardData" >
          <p class="modal__title">carte Club</p>

          <p class="modal__content">
            {{ modalClubCardData.cardNum }}
          </p>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import Information from '@/components/Information.vue'

export default {
  components: {
    Information
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    clubistId: {
      type: String
    }
  },
  data () {
    return {
      isLoading: false,
      currentPage: 1,
      displayCount: 9,
      listAll: [],
      modalStationData: null,
      modalMediaData: null,
      modalClubCardData: null,
      orderByDateAsc: true,
      modalInfoCashbackRequest: null
    }
  },
  watch: {
    '$route.params.clubistId': 'update',
    listAll: 'onSort'
  },
  computed: {
    list () {
      const list = [...this.listAll]
      const order = this.orderByDateAsc ? 1 : -1
      list.sort((a, b) => a.date < b.date ? order : -order)
      return list
    },
    part () {
      const min = (this.currentPage - 1) * this.displayCount
      const max = min + this.displayCount
      return this.list.slice(min, max)
    }
  },
  created () {
    this.update()
  },
  methods: {
    onSort ({ prop = 'date', order = null }) {
      if (prop === 'date') {
        if (order === 'ascending' || order === null) {
          this.orderByDateAsc = true
        } else if (order === 'descending') {
          this.orderByDateAsc = false
        }
      }
    },
    update () {
      this.currentPage = 1
      this.clubistDisplayCount = 5
      this.listAll = []

      this.isLoading = true
      this.isNotFound = false

      this.$store.dispatch('history/getEvents', { clubistId: this.clubistId })
        .then(list => {
          this.listAll = list
        })
        .catch(error => {
          this.isNotFound = true
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    dateToDateTimeStr (time) {
      const dateOptions = { year: '2-digit', month: 'numeric', day: 'numeric' }
      const date = new Date(time)
      return date.toLocaleDateString('fr-FR', dateOptions) +
        ' ' + date.toLocaleTimeString('fr-FR')
    },
    dateToDateStr (time) {
      const dateOptions = { year: '2-digit', month: 'numeric', day: 'numeric' }
      const date = new Date(time)
      return date.toLocaleDateString('fr-FR', dateOptions)
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
}
.pagination {
  margin: 20px 0 0;
}
.modal {
  text-align: left;
  ::v-deep {
    .el-dialog {
      border-radius: 4px;
    }
    .el-dialog__body {
      margin-top: -58px;
      padding: 40px 32px;
    }
    .modal__title {
      margin: 0 0 8px;
      font-weight: bold;
    }
    .modal__content {}
  }
}
</style>
