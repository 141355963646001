<template>
  <el-dialog
    v-loading="isLoading"
    :visible="isOpen"
    :title="'Désactiver la carte Club'"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="550px"
    :show-close="false"
  >
    <p>L’action de désactivation de la carte Club n’est pas autorisée lorsqu’une demande de Card prépayée est en cours de traitement.</p>

    <p>Merci de bien vouloir renouveler cette demande ultérieurement.</p>

    <el-button type="primary" @click="$emit('close')">
      J'ai compris
    </el-button>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: 0,
      isLoading: false
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  text-align: left;
  ::v-deep {
    .el-alert__description {
      margin-bottom: 0;
    }
  }
}
</style>
