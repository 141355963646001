<template>
  <div v-loading="isLoading" class="list">
    <template v-if="list.length > 0" class="list">
      <div class="legends-container">
        <div class="legend transactions">
          <div class="square"></div>

          <p class="legend-name">Transactions</p>
        </div>

        <div class="legend boosters">
          <div class="square"></div>

          <p class="legend-name">Boosters</p>
        </div>
      </div>

      <el-table :data="part" @sort-change="onSort">
        <el-table-column prop="transactionDate" label="Date Transaction" width="160" sortable="custom">
          <template slot-scope="scope">
            <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ dateToStr(scope.row.transactionDate) }}
              {{ dateToHhmmss(scope.row.transactionDate) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="transactionDate" label="Sem." width="60">
          <template slot-scope="scope">
            <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ dateToWeekNumber(scope.row.transactionDate) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="productName" label="Gammes" width="100">
          <template slot-scope="scope">
            <div class="product-row" :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ scope.row.productName }}
            </div>
          </template>
        </el-table-column>

        <el-table-column label="Volume des transactions">
          <el-table-column prop="quantity" label="Litres" width="60">
            <template slot-scope="scope">
              <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
                {{ scope.row.quantity ? scope.row.quantity : '-' }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="quantity" label="Reliquat à ajouter">
            <template slot-scope="scope">
              <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
                {{
                  scope.row.quantityConsidered
                    ? Math.round(((scope.row.quantityConsidered + scope.row.quantityRemaining) - scope.row.quantity) * 100) / 100
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="quantityConsidered" label="Litres + reliquat">
            <template slot-scope="scope">
              <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
                {{
                  scope.row.quantityConsidered
                    ? Math.round((scope.row.quantityConsidered + scope.row.quantityRemaining) * 100) / 100
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>

          <el-table-column prop="quantityRemaining" label="Reliquat restant" width="80">
            <template slot-scope="scope">
              <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
                {{ scope.row.quantityRemaining ? scope.row.quantityRemaining : '-' }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column prop="cashback" label="Cashback (dhs)">
          <template slot-scope="scope">
            <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ scope.row.isTriggeredBy === 'CASHBACK' ? scope.row.cashback : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="multiplier" label="Coeff. de Booster">
          <template slot-scope="scope">
            <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ scope.row.multiplier ? getCoeffBooster(scope.row.multiplier, scope.row.isTriggeredBy) : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="booster" label="Booster* (dhs)">
          <template slot-scope="scope">
            <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ scope.row.isTriggeredBy === 'BOOSTER' ? scope.row.cashback : '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="multiplier" label="Alim. jauge (dhs)">
          <template slot-scope="scope">
            <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ scope.row.cashback || '-' }}
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="date" label="Date Alim. jauge">
          <template slot-scope="scope">
            <div :class="{ 'booster-row': scope.row.isTriggeredBy === 'BOOSTER' }">
              {{ scope.row.date ? dateToStr(scope.row.date) : '-' }}
            </div>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage"
        :total="list.length"
        :page-size="displayCount"
        v-if="!isLoading"
        hide-on-single-page
        @current-change="num => currentPage = num"
        @prev-click="() => currentPage--"
        @next-click="() => currentPage++"
        class="pagination"
      />

      <p class="booster-mention">
        *Booster = (coefficient de booster x cashback) - cashback
      </p>
    </template>

    <p v-else-if="!isLoading" class="empty">
      - {{ $t('tables.empty') }} -
    </p>
  </div>
</template>

<script>
export default {
  name: 'TableClubistLogsAlimentation',

  props: {
    clubistId: {
      type: String
    }
  },

  data () {
    return {
      isLoading: false,
      currentPage: 1,
      displayCount: 15,
      listAll: [],
      modalAmountData: null,
      orderByDateAsc: true,
      modalInfoCashbackRequest: null
    }
  },
  watch: {
    '$route.params.clubistId': 'update',
    listAll: 'onSort'
  },
  computed: {
    list () {
      const list = [...this.listAll]
      const order = this.orderByDateAsc ? 1 : -1
      let i = 0

      list.sort((a, b) => a.transactionDate < b.transactionDate ? order : -order)

      // If there are booster + cashback transactions for the same date,
      // make the booster transaction first before the cashback.
      while (i < list.length) {
        if (
          list[i + 1] &&
          list[i].transactionDate === list[i + 1].transactionDate &&
          list[i].isTriggeredBy === 'CASHBACK' &&
          list[i + 1].isTriggeredBy === 'BOOSTER'
        ) {
          let temp = list[i]

          list[i] = list[i + 1]
          list[i + 1] = temp
          i += 2
        } else {
          i += 1
        }
      }

      return list
    },
    part () {
      const min = (this.currentPage - 1) * this.displayCount
      const max = min + this.displayCount
      return this.list.slice(min, max)
    }
  },

  methods: {
    getCoeffBooster (value, triggeredBy) {
      if (triggeredBy !== 'BOOSTER') {
        return '-'
      } else {
        return value + 1
      }
    },

    onSort ({ prop = 'transactionDate', order = null }) {
      if (prop === 'transactionDate') {
        if (order === 'ascending' || order === null) {
          this.orderByDateAsc = true
        } else if (order === 'descending') {
          this.orderByDateAsc = false
        }
      }
    },

    update () {
      this.currentPage = 1
      this.clubistDisplayCount = 5
      this.listAll = []

      this.isLoading = true
      this.isNotFound = false

      this.$store.dispatch('history/getGaugeHistories', { clubistId: this.clubistId })
        .then(list => {
          this.listAll = list
        })
        .catch(error => {
          this.isNotFound = true
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    dateToStr (time) {
      const options = { year: '2-digit', month: 'numeric', day: 'numeric' }
      return new Date(time).toLocaleDateString('fr-FR', options)
    },

    dateToHhmmss (time) {
      return new Date(time).toLocaleTimeString('fr-FR')
    },

    dateToWeekNumber (time) {
      // Copy date so don't modify original
      let d = new Date(time)

      // Set to nearest Thursday: current date + 4 - current day number
      // Make Sunday's day number 7
      d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))

      // Get first day of year
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))

      // Calculate full weeks to nearest Thursday
      const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)

      return weekNo
    },

    dateToLittleStr (time) {
      return this.dateToStr(time).substring(0, 5)
    },

    getPositive (amount) {
      return amount > 0 ? '+&nbsp;' + amount + '&nbsp;dhs' : ''
    },

    getNegative (amount) {
      return amount < 0 ? '-&nbsp;' + (-amount) + '&nbsp;dhs' : ''
    }
  },

  created () {
    this.update()
  }
}
</script>

<style lang="scss" scoped>
.legends-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;

  .legend {
    display: flex;
    align-items: center;

    .square {
      width: 24px;
      height: 24px;
      margin-right: 10px;
      border: 1px solid #EBEEF5;
    }

    .legend-name {
      margin: 0;
      font-size: 14px;
    }

    &:not(:last-child) {
      margin-right: 40px;
    }
  }

  .transactions .square {
    background-color: #FFFFFF;
  }

  .boosters .square {
    background-color: rgba(244, 149, 0, 0.1);
  }
}
.list {
  margin-top: 27px;
  min-height: 200px;
  text-align: center;
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
}
.pagination {
  margin: 20px 0 0;
}
.red {
  color: $red;
}
.blue {
  color: $blue;
}
.green {
  color: $green;
}
.orange {
  color: $orange;
}
.grey {
  color: $grey;
}
.modal {
  ::v-deep {
    .el-dialog__body {
      margin-top: -50px;
    }
  }
}
.booster-mention {
  margin: 24px 4px 0 0px;
  font-size: 12px;
  text-align: right;
}
.product-row {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}
::v-deep {
  .el-table__header-wrapper thead tr:nth-child(1) th:nth-child(4) {
    text-align: center;
  }

  .el-table__header-wrapper thead tr th:nth-child(5) .cell {
    padding: 0 8px;
  }

  .el-table th > .cell {
    word-break: break-word;
    word-wrap: break-word;
  }

  .el-table__row td,
  .el-table__row td .cell {
    padding: 0;
  }

  .booster-row {
    padding: 12px 0;
    background: rgba(244, 149, 0, 0.1);
  }
}
.el-table td div {
  padding: 10px 8px;
}
</style>
