<template>
  <div class="city-relais">
    <div class="btn-container">
      <button @click="$emit('onchange', 0)" class="city-btn" :class="{ 'is-active': selected === 0 }">
        <div class="bg"></div>
        <City width="30" height="30" class="icon" />
      </button>
      <button @click="$emit('onchange', 1)" class="relais-btn" :class="{ 'is-active': selected === 1 }">
        <div class="bg"></div>
        <Relai width="35" height="35" class="icon" />
      </button>
    </div>
    <div class="label-container">
      <span class="city-label">
        Ville
      </span>
      <span class="relais-label">
        Livraison
      </span>
    </div>
  </div>
</template>

<script>
import City from '@/icons/City.vue'
import Relai from '@/icons/Relai.vue'

export default {
  props: {
    selected: {
      type: Number,
      required: true
    }
  },
  components: {
    City,
    Relai
  }
}
</script>

<style lang="scss" scoped>
.city-relais {
  width: 175px;
  margin: 0 auto;
  .btn-container, .label-container {
    display: flex;
  }

  .btn-container {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      display: block;
      top: 50%;
      left: 25%;
      height: 2px;
      width: 50%;
      background: $blue;
    }
    .city-btn, .relais-btn {
      width: 64px;
      height: 64px;
      border: 0;
      background: 0;
      cursor: pointer;
      position: relative;

      transform: scale(0.75, 0.75);
      transition: transform 0.3s;

      .bg {
        background: #FFF;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 100%;
        border: 3px solid $blue;
        transition: background-color 0.3s;
      }

      .icon {
        position: relative;
        transition: color 0.3s;
        ::v-deep .color {
          fill: $blue;
          stroke: $blue;
          transition: fill 0.3s, stroke 0.3s;
        }
      }

      &.is-active {
        transform: scale(1, 1);
        .bg {
          background: $blue;
          border: 0;
        }
        .icon {
          ::v-deep .color {
            fill: #FFF;
            stroke: #FFF;
          }
        }
      }
    }
    .city-btn {
      margin-right: auto;
      .bg { }
      .icon { }
    }
    .relais-btn {
      .bg { }
      .icon { }
    }
  }
  .label-container {
    margin: 10px 0 20px;
    .city-label, .relais-label {
      width: 64px;
      text-align: center;
      color: #164194;
    }
    .city-label {
      margin-right: auto;

    }
    .relais-label {

    }
  }

  &.is-red {
    .btn-container {
      &:before {
        background: white;
      }
      .city-btn, .relais-btn {
        .bg {
          background: $red;
          border-color: white;
        }

        .icon {
          ::v-deep .color {
            fill: white;
            stroke: white;
          }
        }

        &.is-active {
          .bg {
            background: white;
          }
          .icon {
            ::v-deep .color {
              fill: red;
              stroke: red;
            }
          }
        }
      }
    }
  }
}
</style>
