<template>
  <div class="informations-page page">
    <div class="container">
      <ClubistHead>
        <template v-if="isNotFound">
          Clubiste introuvable
        </template>

        <template v-else-if="clubist">
          {{ clubist.firstName }} {{ clubist.lastName }}

          <Status :status="clubist.statusID" />
        </template>
      </ClubistHead>

      <ClubistBody
        :clubist-id="clubistId"
        :display-menu="!isNotFound"
        :is-loading="isLoading"
        :all-requests="allRequestsPending"
        :cashback-complains-requests="cashbackComplainsRequestsPending"
        :status-update-requests="statusUpdateRequestsPending"
      >
        <router-view
          :clubist-id="clubistId"
          :clubist="clubist"
          :all-requests="allRequestsPending"
          :cashback-complains-requests="cashbackComplainsRequestsPending"
          :status-update-requests="statusUpdateRequestsPending"
          @change="update()"
        />
      </ClubistBody>
    </div>
  </div>
</template>

<script>
import ClubistHead from '@/components/ClubistHead.vue'
import ClubistBody from '@/components/ClubistBody.vue'
import Status from '@/components/Status.vue'

const createDefaultClubist = () => ({
  isLoading: true,
  civility: null,
  firstName: '',
  city: '',
  lastName: '',
  cardNum: null,
  hasAlreadyClaimPrepaidCard: false,
  cashback: 0,
  phone: '',
  email: '',
  statusID: 0,
  birthDate: '',
  isTaxi: false,
  isOptinSms: null,
  isOptinEmail: null,
  language: 'fr'
})

export default {
  components: {
    ClubistHead,
    ClubistBody,
    Status
  },

  data () {
    return {
      isNotFound: false,

      isStatusModalOpen: false,
      isModalClubCardOpen: false,
      isModalClubCardAddOpen: false,
      isModalPrepaidCardAddOpen: false,

      isLoadingCashbackComplainsRequests: false,
      isLoadingStatusUpdateRequests: false,

      cashbackComplainsRequests: [],
      statusUpdateRequests: [],

      clubist: createDefaultClubist()
    }
  },

  computed: {
    clubistId () {
      return this.$route.params.clubistId
    },

    cashbackComplainsRequestsPending () {
      return this.cashbackComplainsRequests.filter((complain) => complain.state === 'pending')
    },

    statusUpdateRequestsPending () {
      return this.statusUpdateRequests.filter((complain) => complain.state === 'pending')
    },

    allRequests () {
      return [...this.cashbackComplainsRequests, ...this.statusUpdateRequests]
    },

    allRequestsPending () {
      return [...this.cashbackComplainsRequestsPending, ...this.statusUpdateRequestsPending]
    },

    isLoading () {
      return this.isLoadingCashbackComplainsRequests || this.isLoadingStatusUpdateRequests
    }
  },

  watch: {
    '$route.params.clubistId': 'update'
  },

  created () {
    this.update()
  },

  methods: {
    initClubist () {
      this.clubist = createDefaultClubist()
    },

    update () {
      this.initClubist()

      this.isNotFound = false

      this.$store.dispatch('refreshClubist', { id: this.clubistId })
        .then(clubist => {
          this.clubist = JSON.parse(JSON.stringify(clubist))

          return clubist
        })
        .then(clubist => {
          this.loadCashbackComplainsRequests()
          this.loadStatusUpdateRequests()
        })
        .catch(error => {
          this.isNotFound = true

          this.$message.error(this.$t(error.message))
        })
    },

    loadCashbackComplainsRequests () {
      this.isLoadingCashbackComplainsRequests = true

      this.$store.dispatch('marketing/loadCashbackComplainsRequests', { clubistId: this.clubist.id })
        .then(complainsRequests => {
          this.cashbackComplainsRequests = complainsRequests
        })
        .finally(() => {
          this.isLoadingCashbackComplainsRequests = false
        })
    },

    loadStatusUpdateRequests () {
      this.isLoadingStatusUpdateRequests = true

      this.$store.dispatch('marketing/loadStatusUpdateRequests', { clubistId: this.clubist.id })
        .then(statusRequests => {
          this.statusUpdateRequests = statusRequests
        })
        .finally(() => {
          this.isLoadingStatusUpdateRequests = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.informations-page {
  .el-form {
    margin-bottom: 25px;
  }
}
.el-col-separator {
  border-right: 1px solid #d8d8d8;
}
.clubist-status {
  color: $blue;
  font-weight: bold;
}
.switch {
  margin-top: 1em;
  margin-bottom: 3em;
}
</style>
