<template>
  <el-dialog
    v-loading="isLoading"
    :visible="isOpen"
    title="Demande de mise à jour de statut"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="730px"
    data-cy="claim-block-modal"
    @closed="onClosed"
  >
    <el-form
      ref="form"
      :model="inputs"
      label-position="top"
      :rules="rules"
      @submit.native.prevent
    >
      <p>Cette action requiert une vérification au préalable.</p>

      <el-form-item>
        <el-radio-group v-model="statusIDInput" class="radio-group-container">
          <div class="radio-container">
            <el-radio :label="0" border data-cy="status-update-normal-radio">
              <Status :status="0" :display-text="true"></Status>
            </el-radio>

            <p class="radio-label">Clubiste en règle</p>
          </div>

          <div class="radio-container">
            <el-radio :label="1" border :disabled="true" data-cy="status-update-warning-radio">
              <Status :status="1" :display-text="true"></Status>
            </el-radio>

            <p class="radio-label radio-label__disabled">Clubiste en mesure de cumuler du cashback mais interdit d'en débloquer</p>
          </div>

          <div class="radio-container">
            <el-radio :label="2" border data-cy="status-update-block-radio">
              <Status :status="2" :display-text="true"></Status>
            </el-radio>

            <p class="radio-label">Clubiste interdit de cumuler du cashback ainsi que d'en débloquer</p>
          </div>
        </el-radio-group>
      </el-form-item>

      <p class="textarea-label">Veuillez vérifier les 3 dernières transactions du clubiste puis renseigner l'objet de sa déclaration ci-dessous :</p>

      <el-form-item prop="comment" label="" class="comment">
        <el-input
          type="textarea"
          data-cy="block-message"
          :autosize="{ minRows: 4, maxRows: 4}"
          placeholder="Renseignez ici le détail de la réclamation"
          v-model="inputs.comment"
        >
        </el-input>
      </el-form-item>

      <div class="align-left">
        <el-row type="flex" align="middle">
          <el-col class="flex-1">
            <button @click="event => $refs.inputFile.click(event)" class="upload-btn">Joindre justificatif</button>
          </el-col>
          <el-col>
            <p>{{ inputs.fileName }}</p>
          </el-col>
        </el-row>
        <p class="infos">{{ fileInfo }}</p>
      </div>

      <input
        ref="inputFile"
        type="file"
        @change="updateFile($event)"
        :accept="fileAccept"
        class="hidden" />

    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">Annuler</el-button>

      <el-button
        type="primary"
        @click="saveStatus()"
        :disabled="isButtonDisabled"
        data-cy="submit"
      >
        Confirmer
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import Status from '@/components/Status.vue'
import UploadAttachedFile from '@/mixins/UploadAttachedFile.js'
import { createRequired } from '@/data/input-rules'

export default {
  mixins: [
    UploadAttachedFile
  ],

  props: {
    clubistId: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    statusID: {
      type: Number,
      default: 0
    }
  },

  components: {
    Status
  },

  data () {
    return {
      isLoading: false,
      statusIDInput: JSON.parse(JSON.stringify(this.statusID)),
      maxFileSize: 20000,
      inputs: {
        comment: '',
        fileName: ''
      },
      rules: {
        comment: [
          createRequired('commentaire', { message: 'Veuillez renseigner les détails de la réclamation' })
        ]
      }
    }
  },

  computed: {
    isButtonDisabled () {
      return this.statusID === this.statusIDInput
    }
  },

  methods: {
    sendRequest () {
      const category = 'STATUS_MODIFIER'
      const clubistPayload = {
        id: this.clubistId,
        statusRequest: {
          status: this.statusIDInput,
          message: this.inputs.comment
        }
      }

      if (this.file) {
        return this.uploadFile(category)
          .then(fileData => {
            clubistPayload.statusRequest.file = fileData
            return this.$store.dispatch('editClubist', clubistPayload)
          })
      } else {
        return this.$store.dispatch('editClubist', clubistPayload)
      }
    },

    saveStatus () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true

          this.sendRequest()
            .then(() => this.$message({
              message: 'La demande de mise à jour de statut a été effectuée',
              type: 'success'
            }))
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false

              this.$emit('submit')
              this.$emit('close')
            })
        }
      })
    },

    onClosed () {
      this.statusIDInput = this.statusID
      this.inputs.comment = ''
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form {
  padding: 0 2rem;
}

::v-deep .el-radio-group {
  display: flex;
  flex-direction: column;
}

::v-deep .el-radio__input {
  display: none;
}

::v-deep .el-radio {
  transition: all 0.25s ease-in-out;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

::v-deep .el-radio:hover:not(.is-disabled) {
  border-color: #164194;
}

::v-deep .el-radio.is-checked {
  background-color: #164194;
}

::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #fff;
}

::v-deep .el-form-item__content {
  max-height: none;
}

.radio-container {
  display: flex;
  margin-bottom: 1rem;

  .radio-label {
    display: inline-block;
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    text-align: left;
    color: #000;

    &__disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.align-left {
  text-align: left;
}

.flex-1 {
  flex: 1;
}

.hidden {
  display: none;
}

.infos {
  font-size: 12px;
  opacity: 0.5;
}

.upload-btn {
  background: $blue;
  color: #FFF;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  padding: 0.5em 1.9em;
  margin: 0.8em 0.8em 0.6em 0;
  white-space: nowrap;
}

.textarea-label {
  text-align: left;
}

::v-deep .el-textarea textarea {
  font-family: Roboto, sans-serif;
}
</style>
