<template>
  <div v-loading="isLoading">

    <p>Renseignez les informations du clubiste pour lui permettre de récupérer sa Card prépayée.</p>
    <CityRelais :selected="state === 'cities-list' ? 0 : 1" @onchange="(num) => navTo(num === 0 ? 'cities-list' : 'station-list')" />

    <el-form
      v-if="state === 'cities-list'"
      :model="address"
      ref="cityForm"
      label-position="top"
      :rules="rules"
    >
      <h3>Ville</h3>
      <el-form-item prop="city">
        <el-autocomplete
          v-model="address.city"
          :fetch-suggestions="querySearchCities"
          :trigger-on-focus="true"
          label="Ville"
          placeholder="Renseignez votre ville"
          class="w-100"
        ></el-autocomplete>
      </el-form-item>
    </el-form>

    <el-form
      v-else-if="state === 'station-list'"
      :model="address"
      ref="stationForm"
      label-position="top"
      :rules="rules"
    >
      <h3>Point relais</h3>
      <el-form-item prop="stationOption">
        <el-select v-model="address.stationOption" placeholder="Select" class="w-100">
          <el-option
            v-for="item in stationsOption"
            :key="item.value"
            :label="item.name + ', ' + item.address"
            :value="item.value"
          >
            <strong>{{ item.name }}</strong>, <span>{{ item.address }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>

    <div v-else-if="state === 'address'" class="form">

      <p class="error">Il n’y a pas encore de point relais dans cette ville.</p>
      <strong class="sub-title">Veuillez renseigner l’adresse du clubiste.</strong>

      <el-form :model="address" :rules="rules" label-position="top" ref="address" class="address">
        <el-form-item label="N° et Nom de la rue" prop="street">
          <el-input @keyup.enter.native="submit" v-model="address.street"></el-input>
        </el-form-item>
        <el-form-item label="Complément d'adresse" prop="complement">
          <el-input @keyup.enter.native="submit" v-model="address.complement"></el-input>
        </el-form-item>
        <el-form-item label="Quartier, Bloc" prop="district">
          <el-input @keyup.enter.native="submit" v-model="address.district"></el-input>
        </el-form-item>
        <el-form-item label="Ville" prop="city">
          <el-input @keyup.enter.native="submit" v-model="address.city"></el-input>
        </el-form-item>
        <el-form-item label="Code Postal" prop="zipcode">
          <el-input @keyup.enter.native="submit" v-model="address.zipcode"></el-input>
        </el-form-item>
      </el-form>

    </div>
  </div>
</template>

<script>
import CityRelais from '@/components/CityRelais.vue'
import { zipCodeRule, createRequired } from '@/data/input-rules'

const stationToOption = station => ({
  value: station.id,
  name: station.name + ', ' + station.city,
  address: station.street
})

// States
// - cities-list
// - station-list
// - address

export default {
  props: {
    clubistId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'claimPrepaidCard'
    }
  },
  data () {
    return {
      state: 'cities-list',
      isLoading: false,
      address: {
        stationOption: null,
        street: null,
        complement: null,
        district: null,
        zipcode: null,
        city: null
      },
      rules: {
        street: [
          createRequired('rue')
        ],
        city: [
          createRequired('ville', { trigger: 'change' })
        ],
        stationOption: [
          createRequired('point relais', { trigger: 'change' })
        ],
        zipcode: zipCodeRule
      }
    }
  },
  watch: {
    state (state) {
      if (state === 'station-list' && this.stationsOption.length < 1) {
        this.state = 'address'
      } else if (state === 'station-list') {
        this.address.stationOption = null
      }

      const label = this.getNextButtonLabel(state)
      this.$emit('label-change', label)
    }
  },
  components: {
    CityRelais
  },
  computed: {
    stationsOption () {
      return this.$store.state.stations
        .filter(station => station.city.toLowerCase() === this.address.city.toLowerCase()) // Keep only stations in same city
        .sort((a, b) => a.name.localeCompare(b.name) || a.street.localeCompare(b.street)) // Sort stations by alphabetical order: name, street
        .map(stationToOption)
    }
  },
  created () {
    if (this.$store.state.stations.length < 1) {
      this.isLoading = true
      this.$store.dispatch('updateStations')
        .catch(error => console.error(error.message))
        .finally(() => { this.isLoading = false })
    }
  },
  methods: {
    querySearchCities (queryCity, cb) {
      const indexOf = (word, letters) => word.toLowerCase().indexOf(letters.toLowerCase())
      const max = Number.MAX_SAFE_INTEGER
      let list = this.$store.state.stations
        .map(({ city }) => city) // Get all cities from stations
        .filter((city, index, array) => array.indexOf(city) === index) // Remove double

      if (!queryCity || queryCity === '') {
        list = list
          .sort((a, b) => a.localeCompare(b)) // Sort cities by alphabetical order
      } else {
        list = list
          .filter(city => indexOf(city, queryCity) > -1) // Keep only with same letters
          .sort((a, b) => indexOf(a, queryCity) - indexOf(b, queryCity) || a.localeCompare(b)) // Sort cities by: shared word upstream, alphabetical order
      }

      list = list.filter((_, index) => index < max) // Keep only max results
        .map(city => ({ value: city, city }))

      cb(list)
      return list
    },

    getNextButtonLabel (state = this.state) {
      const next = 'Suivant'
      const unlock = 'Confirmer'
      const labels = {
        'cities-list': next,
        'station-list': unlock,
        'address': unlock
      }

      return labels[state]
    },
    navTo (state) {
      if (state !== this.state) {
        if (state === 'cities-list') {
          this.state = state
        } else if (state === 'station-list') {
          this.checkCityValid()
            .then(() => {
              this.state = state
            })
            .catch(error => console.error(error.message))
        }
      }
    },
    claimPrepaidCard (byStation = true) {
      const payload = {
        clubistId: this.clubistId
      }
      if (byStation) {
        payload.station = this.$store.state.stations.find(station => station.id === this.address.stationOption)
      } else {
        payload.address = {
          street: this.address.street,
          complement: this.address.complement,
          district: this.address.district,
          zipcode: this.address.zipcode,
          city: this.address.city
        }
      }

      return this.$store.dispatch(this.type, payload)
    },

    blockPrepaidCard (byStation = true) {
      const payload = {
        clubistId: this.clubistId
      }
      if (byStation) {
        payload.station = this.$store.state.stations.find(station => station.id === this.address.stationOption)
      } else {
        payload.address = {
          street: this.address.street,
          complement: this.address.complement,
          district: this.address.district,
          zipcode: this.address.zipcode,
          city: this.address.city
        }
      }

      return this.$store.dispatch('blockPrepaidCard', payload)
    },

    checkCityValid () {
      return new Promise((resolve, reject) => {
        if (this.$refs.cityForm) {
          this.$refs.cityForm.validate(valid => {
            if (valid) {
              resolve(true)
            } else {
              reject(new Error('Form not valid'))
            }
          })
        } else {
          reject(new Error('Form not created'))
        }
      })
    },

    checkStationValid () {
      return new Promise((resolve, reject) => {
        if (this.$refs.stationForm) {
          this.$refs.stationForm.validate(valid => {
            if (valid) {
              resolve(true)
            } else {
              reject(new Error('Form not valid'))
            }
          })
        } else {
          reject(new Error('Form not created'))
        }
      })
    },

    checkAddressValid () {
      return new Promise((resolve, reject) => {
        if (this.$refs.address) {
          this.$refs.address.validate(valid => {
            if (valid) {
              resolve(true)
            } else {
              reject(new Error('Form not valid'))
            }
          })
        } else {
          reject(new Error('Form not created'))
        }
      })
    },

    next () {
      if (this.state === 'cities-list') {
        this.checkCityValid()
          .then(() => {
            this.state = 'station-list'
          })
          .catch(error => console.error(error.message))
      } else if (this.state === 'station-list') {
        this.isLoading = true
        this.checkStationValid()
          .then(() => {
            return this.claimPrepaidCard(true)
              .then(() => {
                this.$emit('submit')

                return this.$message({
                  message: `L'édition de la Card prépayée a bien été effectuée`,
                  type: 'success'
                })
              })
              .catch(error => this.$message.error(this.$t(error.message)))
              .finally(() => {
                this.$emit('close')
              })
          })
          .catch(error => console.error(error.message))
          .finally(() => {
            this.isLoading = false
          })
      } else if (this.state === 'address') {
        this.isLoading = true
        this.checkAddressValid()
          .then(() => {
            return this.claimPrepaidCard(false)
              .then(() => {
                this.$emit('submit')

                this.$message({
                  message: `L'édition de la Card prépayée a bien été effectuée`,
                  type: 'success'
                })
              })
              .catch(error => this.$message.error(this.$t(error.message)))
              .finally(() => {
                this.$emit('close')
              })
          })
          .catch(error => console.error(error.message))
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.w-100 {
  width: 100%;
}

h3 {
  text-align: left;
  margin-bottom: 0.4em;
}

.sub-title {
  display: block;
  margin-bottom: 2em;
}

.error {
  color: $red;
}

p.error:not(:last-child){
  margin-bottom: 0.5em;
}

.address {
  text-align: left;
}
</style>
