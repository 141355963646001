<template>
  <svg :width="width" :height="height" viewBox="0 0 24.247 24" xml:space="preserve">

    <g transform="translate(0.165)">
      <g transform="translate(1.29 2.499)">
        <path fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" d="M8,17l4,4,4-4" transform="translate(-1.29 -2.499)"/>
        <line fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" y2="9" transform="translate(10.71 9.501)"/>
        <path fill="none" :stroke="color" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px" d="M20.88,18.09A5,5,0,0,0,18,9H16.74A8,8,0,1,0,3,16.29" transform="translate(-1.29 -2.499)"/>
      </g>
      <rect fill="#fcfcfc" opacity="0" width="24" height="24"/>
    </g>

  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000'
    },
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
