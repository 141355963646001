<template>
  <section v-if="!isNotFound" v-loading="isLoading || clubist.isLoading" class="section-container">
    <el-row type="flex" :gutter="75">
      <el-col :span="12" class="el-col-separator">

        <el-form
          :model="clubistInputs"
          ref="form"
          :rules="rules"
          label-position="top"
          class="form"
        >
          <h2>Informations générales</h2>
          <el-row :gutter="25">
            <el-col :span="12">
              <!-- Civility -->
              <el-form-item data-cy="civility" prop="civility" label="Civilité">
                <el-radio-group v-model="clubistInputs.civility" :disabled="isBanned">
                  <el-radio label="female" border>Mme</el-radio>
                  <el-radio label="male" border>M.</el-radio>
                </el-radio-group>
              </el-form-item>

              <!-- First name -->
              <el-form-item data-cy="first-name" prop="firstName" label="Prénom">
                <el-input maxlength="25" v-model="clubistInputs.firstName" :disabled="isBanned" @keyup.enter.native="submit"></el-input>
              </el-form-item>

              <!-- Birth date -->
              <el-form-item data-cy="birth-date" prop="birthDate" label="Date de naissance">
                <el-input type="text" v-model="clubistInputs.birthDate" :disabled="isBanned" maxlength="10" placeholder="dd/mm/yyyy" @keyup.enter.native="submit"></el-input>
              </el-form-item>

              <!-- Email -->
              <el-form-item data-cy="email" prop="email" :rules="clubistInputs.isOptinEmail ? rules.emailIsOptin : rules.emailNoOptin" label="Adresse email">
                <el-input v-model="clubistInputs.email" :disabled="isBanned" @keyup.enter.native="submit"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">

              <!-- Last name -->
              <el-form-item data-cy="last-name" prop="lastName" label="Nom">
                <el-input maxlength="25" v-model="clubistInputs.lastName" :disabled="isBanned" @keyup.enter.native="submit"></el-input>
              </el-form-item>

              <!-- Phone -->
              <el-form-item data-cy="phone" prop="phone" label="N° de téléphone">
                <el-input minlength="12" maxlength="12" show-word-limit v-model="clubistInputs.phone" :disabled="isBanned" @keyup.enter.native="submit"></el-input>
              </el-form-item>

              <!-- City -->
              <el-form-item data-cy="city" prop="city" label="Ville">
                <el-input maxlength="25" v-model="clubistInputs.city" :disabled="isBanned" @keyup.enter.native="submit"></el-input>
              </el-form-item>

              <!-- Password -->
              <el-form-item label="Mot de passe">
                <el-button @click.native="openModalPassword" :disabled="isBanned">Modifier le mot de passe</el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Vehicle -->
          <el-row :gutter="25" class="switch">
            <el-col :span="10">
              Type de véhicule <span class="asterisk">*</span> :
            </el-col>
            <el-col data-cy="vehicle" :span="14">
              <el-switch
                class="element-switch"
                v-model="clubistInputs.isTaxi"
                :disabled="isBanned"
                active-text="Taxi"
                inactive-text="">
              </el-switch>
            </el-col>
          </el-row>

          <!-- Opt-in -->
          <h2>Préférences de contact</h2>
          <p class="large-label">Accepte être contacté par TotalEnergies Maroc à des fins d’enquêtes ou d’information et souhaite recevoir les offres commerciales :</p>
          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item data-cy="optin-email" prop="isOptinEmail" label="Par email" class="items-inline">
                <el-radio-group v-model="clubistInputs.isOptinEmail" :disabled="isBanned">
                  <el-radio :label="true">Oui</el-radio>
                  <el-radio :label="false">Non</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item data-cy="optin-sms" prop="isOptinSms" label="Par SMS" class="items-inline">
                <el-radio-group v-model="clubistInputs.isOptinSms" :disabled="isBanned">
                  <el-radio :label="true">Oui</el-radio>
                  <el-radio :label="false">Non</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Email/SMS language -->
          <p class="large-label">Langue pour recevoir mes communications email/SMS</p>
          <el-row :gutter="25">
            <el-col :span="12">
              <el-form-item data-cy="language" prop="language" label="">
                <el-radio-group v-model="clubistInputs.language" :disabled="isBanned">
                  <el-radio label="fr">Français</el-radio>
                  <el-radio label="ar">Arabe</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Submit -->
          <el-row data-cy="clubist-save" type="flex" justify="center">
            <el-button type="danger" @click="submit" :disabled="isBanned">Enregistrer</el-button>
          </el-row>
        </el-form>
      </el-col>
      <el-col :span="12">

        <!-- Status -->
        <h2>Statut</h2>
        <el-form class="form" data-cy="profile-status">
          <el-row type="flex" align="middle" :gutter="25">
            <el-col :span="14">
              <Status :status="clubistInputs.statusID" :display-text="true" class="important"></Status>
            </el-col>

            <el-col :span="10">
              <el-button
                :disabled="statusUpdateRequests.length > 0 || isBanned"
                data-cy="clubist-block-cta"
                @click.native="isStatusModalOpen = true"
              >
                Modifier le statut
              </el-button>
            </el-col>
          </el-row>

          <el-row>
            <el-alert
              v-if="statusUpdateRequests.length > 0"
              show-icon
              title=""
              type="warning"
              class="alert mt-16"
              :closable="false"
            >
              Une demande de changement de statut est déjà en cours pour ce clubiste.
            </el-alert>
          </el-row>
        </el-form>

        <!-- Vehicle -->
        <h2>Profil</h2>
        <el-row type="flex" align="middle" class="form" :gutter="25">
          <el-col :span="24">
            <p>Basé sur les transactions du clubiste : <strong class="important uppercase" v-html="vehicle"></strong></p>
          </el-col>
        </el-row>

        <!-- Cards -->
        <h2>Gestion des cartes</h2>
        <el-form label-position="top" class="form">

          <!-- Club card -->
          <el-row type="flex" align="bottom" :gutter="25">
            <el-col :span="14">
              <el-form-item label="N° de carte Club">
                <el-input v-model="clubistInputs.cardNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <el-button v-if="clubistInputs.cardNum" :disabled="isBanned" @click="handleDisableCard()">Désactiver la carte</el-button>
                <el-button v-else @click="isModalClubCardAddOpen = true" :disabled="isBanned">Ajouter une carte</el-button>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- Prepaid card -->
          <el-row type="flex" align="bottom" :gutter="25">
            <el-col :span="14">
              <el-form-item label="N° de Card prépayée">
                <el-input v-model="clubistInputs.prepaidCardNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <el-button v-if="clubistInputs.prepaidCardNum" @click="isModalPrepaidCardRemoveOpen = true">Bloquer la carte</el-button>
                <el-button v-else-if="!clubistInputs.isPrepaidCardClaimOnDelivery" data-cy="add-card-open-modal" @click="isModalPrepaidCardAddOpen = true" :disabled="isBanned || isWarning">Ajouter une carte</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-alert
            v-if="clubistInputs.isPrepaidCardClaimOnDelivery"
            title=""
            type="warning"
            :closable="false"
            show-icon
            class="alert"
          >
            Votre demande d'envoi de Card prépayée est en cours de traitement.
          </el-alert>
          <el-alert
            v-else-if="clubistInputs.prePaidCardClaimed"
            title=""
            type="warning"
            :closable="false"
            show-icon
            class="alert"
          >
            Le clubiste a déjà bénéficié de l'envoi d'une Card prépayée offerte.
          </el-alert>

        </el-form>

        <!-- Contest -->
        <div class="contest mt-64">
          <h2>Tirage au sort</h2>
          <el-row type="flex" align="middle" :gutter="25">
            <el-col :span="24">
              <p>
                Période du <strong>{{ begin }}</strong> au <strong>{{ end }}</strong>
              </p>
              <p>
                <strong class="important">
                  Eligible:
                  <template v-if="clubist.isWinAllowed">Oui</template>
                  <template v-else>Non</template>
                </strong>
              </p>
              <p class="mt-16">
                Tirage au sort dans: {{ counter.days }}j {{ counter.hours }}h {{ counter.minutes }}min
              </p>

            </el-col>
          </el-row>
        </div>

      </el-col>
    </el-row>

    <!-- Modals -->
    <template>
      <ModalTotalCardAdd
        :clubistId="clubistId"
        :cashback="clubist ? clubist.cashback : 0"
        :isOpen="isModalPrepaidCardAddOpen"
        @close="isModalPrepaidCardAddOpen = false"
        @submit="loadClubist()"
      />

      <ModalStatusEdit
        :clubistId="clubistId"
        :isOpen="isStatusModalOpen"
        :statusID="clubistInputs.statusID"
        @close="isStatusModalOpen = false"
        @submit="loadClubist()"
      />

      <ModalClubCardRemove
        :clubistId="clubistId"
        :isOpen="isModalClubCardOpen"
        @close="isModalClubCardOpen = false"
        @submit="loadClubist()"
      />

      <ModalClubCardRemoveAlerted
        :isOpen="isModalClubCardRemoveAlertedOpen"
        @close="isModalClubCardRemoveAlertedOpen = false"
        @submit="loadClubist()"
      />

      <ModalClubCardRemoveAlertedPPClaim
        :isOpen="isModalClubCardRemoveAlertedPPClaimOpen"
        @close="isModalClubCardRemoveAlertedPPClaimOpen = false"
        @submit="loadClubist()"
      />

      <ModalClubCardAdd
        :clubistId="clubistId"
        :isOpen="isModalClubCardAddOpen"
        @close="isModalClubCardAddOpen = false"
        @submit="loadClubist()"
      />

      <ModalTotalCardRemove
        :clubistId="clubistId"
        :isOpen="isModalPrepaidCardRemoveOpen"
        @close="isModalPrepaidCardRemoveOpen = false"
        @submit="loadClubist()"
      />
    </template>

  </section>

</template>

<script>
import Status from '@/components/Status.vue'

import ModalStatusEdit from '@/modals/StatusEdit.vue'

import ModalClubCardAdd from '@/modals/ClubCardAdd.vue'
import ModalClubCardRemove from '@/modals/ClubCardRemove.vue'
import ModalClubCardRemoveAlerted from '@/modals/ClubCardRemoveAlerted.vue'
import ModalClubCardRemoveAlertedPPClaim from '@/modals/ClubCardRemoveAlertedPPClaim.vue'

import ModalTotalCardAdd from '@/modals/TotalCardAdd.vue'
import ModalTotalCardRemove from '@/modals/TotalCardRemove.vue'
import Counter from '@/mixins/Counter.js'

import {
  cityRule,
  phoneRule,
  emailRule,
  lastnameRule,
  firstnameRule,
  birthDateFrRule,
  createRequired,
  frDateToStdDate,
  stdDateToFrDate,
  formatPartFrDate,
  formatMarocPhone
} from '@/data/input-rules'
import { getStartCurrentLotteryDate, getEndCurrentLotteryDate } from '@/pure/date'

const VEHICLES = {
  CAR: 'car',
  TAXI: 'taxi',
  TRUCK: 'truck',
  NULL: 'null'
}

export default {
  mixins: [ Counter ],
  components: {
    Status,

    ModalStatusEdit,
    ModalClubCardRemove,
    ModalClubCardAdd,
    ModalClubCardRemoveAlerted,
    ModalClubCardRemoveAlertedPPClaim,

    ModalTotalCardAdd,
    ModalTotalCardRemove
  },
  props: {
    clubistId: {
      type: String
    },

    clubist: {
      type: Object
    },

    statusUpdateRequests: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      VEHICLES: VEHICLES,

      isLoading: false,
      isNotFound: false,

      isStatusModalOpen: false,
      isModalClubCardOpen: false,
      isModalClubCardAddOpen: false,
      isModalPrepaidCardAddOpen: false,
      isModalPrepaidCardRemoveOpen: false,
      isModalClubCardRemoveAlertedOpen: false,
      isModalClubCardRemoveAlertedPPClaimOpen: false,

      rules: {
        phone: [
          ...phoneRule,
          createRequired('numéro de téléphone')
        ],
        emailIsOptin: [
          createRequired('email'),
          ...emailRule
        ],
        emailNoOptin: [
          ...emailRule
        ],
        city: [
          ...cityRule
        ],
        lastName: [
          ...lastnameRule,
          createRequired('nom')
        ],
        firstName: [
          ...firstnameRule,
          createRequired('prénom')
        ],
        birthDate: [
          ...birthDateFrRule
        ],
        vehicle: [
          createRequired('véhicule')
        ]
      },

      clubistInputs: null
    }
  },

  computed: {
    isBanned () {
      return this.clubist ? this.clubist.statusID === 2 : false
    },
    isWarning () {
      return this.clubist ? this.clubist.statusID === 1 : false
    },
    begin () {
      return getStartCurrentLotteryDate(new Date(Date.now() + this.$store.getters.clientDelay)).toLocaleDateString('fr-FR')
    },
    end () {
      return getEndCurrentLotteryDate(new Date(Date.now() + this.$store.getters.clientDelay)).toLocaleDateString('fr-FR')
    },
    vehicle () {
      if (this.clubistInputs.isTruck) {
        return 'Poids lourd'
      }

      return 'Automobiliste'
    }
  },

  watch: {
    clubist: 'fillInputsFromClubist',
    'clubistInputs.birthDate': 'onDateChange',
    'clubistInputs.phone': 'onPhoneChange'
  },

  created () {
    this.fillInputsFromClubist(this.clubist)
  },

  destroyed () {
    clearTimeout(this.to)
  },

  methods: {
    handleDisableCard () {
      if (this.clubist && this.clubist.statusID === 1) {
        this.isModalClubCardRemoveAlertedOpen = true
      } else if (this.clubist && this.clubistInputs.isPrepaidCardClaimOnDelivery) {
        this.isModalClubCardRemoveAlertedPPClaimOpen = true
      } else {
        this.isModalClubCardOpen = true
      }
    },
    onPhoneChange (value) {
      let newValue = formatMarocPhone(value)
      if (newValue !== value) {
        this.$nextTick(() => this.$set(this.clubistInputs, 'phone', newValue))
      }
    },
    onDateChange (value, last) {
      let newValue = formatPartFrDate(value, last)
      if (newValue !== value) {
        this.$nextTick(() => this.$set(this.clubistInputs, 'birthDate', newValue))
      }
    },
    fillInputsFromClubist (clubist) {
      const clubistInputs = Object.assign({}, clubist)
      if (clubistInputs.birthDate) {
        clubistInputs.birthDate = stdDateToFrDate(clubistInputs.birthDate)
      }

      clubistInputs.vehicle = ''
      if (clubistInputs.isCar) {
        clubistInputs.vehicle = VEHICLES.CAR
      }

      if (clubistInputs.isTruck) {
        clubistInputs.vehicle = VEHICLES.TRUCK
      }

      if (clubistInputs.isTaxi) {
        clubistInputs.vehicle = VEHICLES.TAXI
      }

      this.clubistInputs = clubistInputs
    },
    submit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.saveClubist()
        }
      })
    },
    saveClubist () {
      this.isLoading = true
      const payload = { }

      // Convert fr date to std date
      const inputsClean = Object.assign({}, this.clubistInputs)
      if (inputsClean.birthDate) {
        inputsClean.birthDate = frDateToStdDate(inputsClean.birthDate)
      }

      // Remove same data
      Object.keys(inputsClean)
        .forEach(key => {
          if (JSON.stringify(inputsClean[key]) !== JSON.stringify(this.clubist[key])) {
            payload[key] = inputsClean[key]
          }
        })

      // Remove unusued property
      delete payload.profile

      payload.id = this.clubist.id
      payload.cardNum = this.clubist.cardNum

      this.$store.dispatch('editClubist', payload)
        .then(data => {
          this.$message({
            message: 'Le clubiste a bien été édité',
            type: 'success'
          })
          return data
        })
        .then(clubist => {
          this.$emit('change')
        })
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isLoading = false
        })
    },
    loadClubist () {
      this.$emit('change')
    },
    openModalPassword () {
      const message = `<div>
        <p>Attention, toute réinitialisation de mot de passe doit être précédée d’une <strong>vérification d’identité du clubiste</strong>.</p><br>
        <p>Une fois la demande validée, un SMS sera envoyé au clubiste avec <b>un lien temporaire de réinitialisation de mot de passe</b>.</p>
      <div>`
      this.$msgbox({
        title: 'Modification de mot de passe',
        message: message,
        showCancelButton: true,
        cancelButtonText: 'Annuler',
        confirmButtonText: 'Valider',
        dangerouslyUseHTMLString: true,
        center: true,
        showClose: false
      }).then(() => {
        return this.$store.dispatch('resetClubistPassword', {
          cardNum: this.clubist.cardNum
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: 'Demande de SMS envoyée'
            })
          })
          .catch(error => this.$message.error(this.$t(error.message)))
      }).catch(error => console.error(error.message))
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-switch__core {
  transform: rotate(180deg) !important;
}
::v-deep .el-switch__label.el-switch__label--right {
  position: absolute;
  left: -50px;
}
* + h2 {
  margin-top: 2.9em;
}
.form {
  margin-bottom: 25px;
}
.el-col-separator {
  border-right: 1px solid #d8d8d8;
}
.important {
  color: $blue;
  font-weight: bold;
}
.large-label {
  margin-bottom: 1em;
}
.uppercase {
  text-transform: uppercase;
}
.switch {
  margin-top: 1em;
  margin-bottom: 3em;
}
.contest {
  line-height: 1.7em;
}
.mt-16 {
  margin-top: 16px;
}
.mt-64 {
  margin-top: 64px;
}
.asterisk {
  color: #df0d36;
  margin-right: 4px;
  font-size: 12px;
}
</style>
