<template>
  <el-dialog
    v-loading="isLoading"
    :visible="isOpen"
    title="Ajouter une carte Club"
    :center="true"
    :modalAppendToBody="false"
    :before-close="() => $emit('close')"
    width="730px"
  >
    <el-row>
      <el-col :offset="4" :span="16" >
        <el-form
          :model="inputs"
          ref="form"
          label-position="top"
          :rules="rules"
        >
          <p>
            Renseignez le N° de la carte Club:
          </p>

          <el-form-item prop="cardNum" label="N° de carte Club">
            <el-input minlength="19" maxlength="19" show-word-limit v-model="inputs.cardNum"></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">Annuler</el-button>
      <el-button type="primary" @click="submit()">Confirmer</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { clubCardNumRule, createRequired } from '@/data/input-rules'

export default {
  props: {
    clubistId: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      inputs: {
        cardNum: ''
      },
      rules: {
        cardNum: [
          ...clubCardNumRule,
          createRequired('numéro de carte')
        ]
      }
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true
          const clubist = {
            id: this.clubistId,
            cardNum: this.inputs.cardNum
          }

          this.$store.dispatch('editClubist', clubist)
            .then(() => {
              this.$emit('submit')
              this.$message({
                message: 'La demande d\'ajout de Carte Club a bien été effectué',
                type: 'success'
              })
            })
            .catch(error => this.$message.error(this.$t(error.message)))
            .finally(() => {
              this.isLoading = false
              this.$emit('close')
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
