<template>
  <el-dialog
    v-loading="isLoading"
    :visible="isOpen"
    :title="title"
    :modalAppendToBody="false"
    :before-close="close"
    :center="true"
    width="730px"
  >
    <template v-if="state === 0">
      <template v-if="cashback < 50">
        <el-row>
          <el-col :offset="4" :span="16" >
            <p>
              Le clubiste possédant moins de 50 dhs il ne peut pas commander de Card prépayée.
            </p>
          </el-col>
        </el-row>
      </template>
    </template>
    <template v-else-if="state === 2">
      <el-row>
        <el-col :offset="4" :span="16" >
          <TotalCardClaim ref="totalCardClaim" :clubistId="clubistId" @submit="$emit('submit')" @label-change="label => this.nextLabel = label" @close="close()"/>
        </el-col>
      </el-row>
    </template>
    <template v-else-if="state === 3">
      <el-row>
        <el-col :offset="4" :span="16">
          <p>
            Le clubiste a déjà bénéficié gratuitement d'un envoi de Card prépayée à domicile ou point relais.
            Vous pouvez <strong>l'inviter à se rendre en station pour acheter une nouvelle carte</strong>.
          </p>
        </el-col>
      </el-row>
    </template>

    <span slot="footer" class="dialog-footer">
      <el-button v-if="state !== 3" @click="close">Annuler</el-button>
      <el-button v-if="cashback >= 50" type="primary" @click="next()">{{ nextLabel }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import TotalCardClaim from '@/components/TotalCardClaim.vue'

export default {
  components: {
    TotalCardClaim
  },
  props: {
    clubistId: {
      type: String,
      required: true
    },
    cashback: {
      type: Number,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      state: 0, // 0: ask, 2: he claim card, 3: no way
      inputs: {
        hasTotalCard: false
      },
      nextLabel: 'Suivant'
    }
  },
  watch: {
    state (state) {
      return [
        'Suivant',
        'Confirmer',
        'Confirmer',
        'J’ai compris'
      ][state]
    },
    isOpen: {
      handler: 'resetState',
      immediate: true
    }
  },
  computed: {
    title () {
      if (this.state === 2) {
        return 'Demande d’une Card prépayée'
      } else if (this.state === 3) {
        return 'Demande non autorisée'
      }

      return 'Ajouter une Card prépayée'
    }
  },
  methods: {
    resetState () {
      if (this.state === 0 && this.cashback >= 50) {
        this.state = 2
      }
    },
    close () {
      this.resetState()
      this.$emit('close')
    },
    next () {
      if (this.state === 0) {
        if (this.cashback >= 50) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.state = 2
            } else {
              return false
            }
          })
        }
      } else if (this.state === 2 && this.$refs.totalCardClaim) {
        this.$refs.totalCardClaim.next()
      } else if (this.state === 3) {
        this.close()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert {
  text-align: left;
  ::v-deep {
    .el-alert__description {
      margin-bottom: 0;
    }
  }
}
</style>
