<template>
  <div v-loading="isLoading" class="list">
    <template v-if="list.length > 0" class="list">
      <el-table :data="part" @sort-change="onSort">
        <el-table-column width="90" prop="createdDate" :label="$t('tables.column.date')" sortable="custom">
          <template slot-scope="scope">
            {{ dateToStr(scope.row.createdDate) }}
          </template>
        </el-table-column>
        <el-table-column width="110" :label="$t('clubistHistory.tables.cashback.column.sub')">
          <template slot-scope="scope">
            <span class="red" v-html="getNegative(scope.row.amount)"></span>
          </template>
        </el-table-column>
        <el-table-column width="110" :label="$t('clubistHistory.tables.cashback.column.add')">
          <template slot-scope="scope">
            <span class="blue" v-html="getPositive(scope.row.amount)"></span>
          </template>
        </el-table-column>
        <el-table-column width="190" prop="clubCard.id" :label="$t('tables.column.cardNum')"></el-table-column>
        <el-table-column width="140" :label="$t('tables.column.status')">
          <template slot-scope="scope">
            <template v-if="scope.row.isReset">
              <i class="el-icon-refresh-left grey"></i> {{ $t('clubistHistory.tables.cashback.column.status.reset') }}
            </template>
            <template v-else-if="scope.row.isPending">
              <i class="el-icon-time orange"></i> {{ $t('clubistHistory.tables.cashback.column.status.wait') }}
            </template>
            <template v-else-if="scope.row.isValidated">
              <i class="el-icon-circle-check green"></i> {{ $t('clubistHistory.tables.cashback.column.status.paid') }}
            </template>
            <template v-else-if="scope.row.isRefused">
              <i class="el-icon-circle-close red"></i> {{ $t('clubistHistory.tables.cashback.column.status.refused') }}
            </template>
            <template v-else-if="scope.row.isExpired">
              <i class="el-icon-time red"></i> {{ $t('clubistHistory.tables.cashback.column.status.expired') }}
            </template>
          </template>
        </el-table-column>
        <el-table-column :label="$t('clubistHistory.tables.cashback.column.detail.label')">
          <template v-if="scope.row.updatedDate" slot-scope="scope">
            {{ $t('clubistHistory.tables.cashback.column.detail.value', { date: dateToLittleStr(scope.row.updatedDate) }) }}
          </template>
        </el-table-column>
        <el-table-column align="right" width="70">
          <template slot-scope="scope">
            <Information v-if="scope.row.amount < 0" @click.native="modalAmountData = scope.row" />
            <Information v-else-if="scope.row.amount > 0 && scope.row.complaint && scope.row.complaint.message" @click.native="modalInfoCashbackRequest = scope.row.complaint" />
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage"
        :total="list.length"
        :page-size="displayCount"
        v-if="!isLoading"
        hide-on-single-page
        @current-change="num => currentPage = num"
        @prev-click="() => currentPage--"
        @next-click="() => currentPage++"
        class="pagination"
      ></el-pagination>
    </template>

    <p v-else-if="!isLoading" class="empty">
      - {{ $t('tables.empty') }} -
    </p>

    <el-dialog
      v-loading="isLoading"
      :visible="modalAmountData !== null"
      title=""
      :center="true"
      :modalAppendToBody="false"
      :before-close="() => modalAmountData = null"
      width="260px"
      class="modal"
    >
      <el-row>
        <el-col :offset="0" :span="24" v-if="modalAmountData" >
          <strong>
            {{
              modalAmountData.operationType === "EXPIRED" || modalAmountData.operationType === "RESET"
                ? $t('clubistHistory.tables.cashback.popin.amount.title')
                : $t('clubistHistory.tables.cashback.popin.amount.titleTransfered')
            }}
          </strong>
          <p>{{ $t('clubistHistory.tables.cashback.popin.amount.value', { amount: -modalAmountData.amount }) }}</p>
          <strong>{{ $t('clubistHistory.tables.cashback.popin.author.title') }}</strong>
          <p>{{ $t('clubistHistory.tables.cashback.popin.author.value.' + modalAmountData.author) }}</p>
        </el-col>
      </el-row>
    </el-dialog>

    <ModalInfoCashbackRequest
      title=""
      :complaint="modalInfoCashbackRequest"
      :isOpen="modalInfoCashbackRequest !== null"
      @close="modalInfoCashbackRequest = null"
    />
  </div>
</template>

<script>
import Information from '@/components/Information.vue'
import ModalInfoCashbackRequest from '@/modals/InfoCashbackRequest.vue'

export default {
  components: {
    Information,
    ModalInfoCashbackRequest
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    clubistId: {
      type: String
    }
  },
  data () {
    return {
      isLoading: false,
      currentPage: 1,
      displayCount: 9,
      listAll: [],
      modalAmountData: null,
      orderByDateAsc: true,
      modalInfoCashbackRequest: null
    }
  },
  watch: {
    '$route.params.clubistId': 'update',
    listAll: 'onSort'
  },
  computed: {
    list () {
      const list = [...this.listAll]
      const order = this.orderByDateAsc ? 1 : -1
      list.sort((a, b) => a.createdDate < b.createdDate ? order : -order)
      return list
    },
    part () {
      const min = (this.currentPage - 1) * this.displayCount
      const max = min + this.displayCount
      return this.list.slice(min, max)
    }
  },
  created () {
    this.update()
  },
  methods: {
    onSort ({ prop = 'createdDate', order = null }) {
      if (prop === 'createdDate') {
        if (order === 'ascending' || order === null) {
          this.orderByDateAsc = true
        } else if (order === 'descending') {
          this.orderByDateAsc = false
        }
      }
    },
    update () {
      this.currentPage = 1
      this.clubistDisplayCount = 5
      this.listAll = []

      this.isLoading = true
      this.isNotFound = false
      this.$store.dispatch('history/getCashbacks', { clubistId: this.clubistId })
        .then(list => {
          this.listAll = list
        })
        .catch(error => {
          this.isNotFound = true
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    dateToStr (time) {
      const options = { year: '2-digit', month: 'numeric', day: 'numeric' }
      return new Date(time).toLocaleDateString('fr-FR', options)
    },
    dateToLittleStr (time) {
      return this.dateToStr(time).substring(0, 5)
    },
    getPositive (amount) {
      return amount > 0 ? '+&nbsp;' + amount + '&nbsp;dhs' : ''
    },
    getNegative (amount) {
      return amount < 0 ? '-&nbsp;' + (-amount) + '&nbsp;dhs' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
}
.pagination {
  margin: 20px 0 0;
}
.red {
  color: $red;
}
.blue {
  color: $blue;
}
.green {
  color: $green;
}
.orange {
  color: $orange;
}
.grey {
  color: $grey;
}
.modal {
  ::v-deep {
    .el-dialog__body {
      margin-top: -50px;
    }
  }
}
</style>
