<template>
  <div v-loading="clubist.isLoading">
    <section class="section-container">

      <div class="head">
        <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="1" data-cy="history-transaction-cta">Transactions</el-menu-item>
          <el-menu-item v-if="isMarketing" index="2" data-cy="history-gauge-cta">Alimentation jauge</el-menu-item>
          <el-menu-item index="3" data-cy="history-cashback-cta">Cashback</el-menu-item>
          <el-menu-item index="4" data-cy="history-event-cta">Événements</el-menu-item>
          <el-menu-item v-if="isMkt" index="5"><Download color="" data-cy="history-export-cta"/> Export</el-menu-item>
        </el-menu>

        <div class="line"></div>
      </div>

      <TableClubistLogsTransactions v-if="activeIndex === '1'" :clubistId="clubistId"/>
      <TableClubistLogsAlimentation v-else-if="activeIndex === '2'" :clubistId="clubistId"/>
      <TableClubistLogsCashback v-else-if="activeIndex === '3'" :clubistId="clubistId"/>
      <TableClubistLogsEvents v-else-if="activeIndex === '4'" :clubistId="clubistId"/>
      <HistoryExports v-else-if="activeIndex === '5'" :clubistId="clubistId"/>

    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import TableClubistLogsCashback from '@/components/TableClubistLogsCashback.vue'
import TableClubistLogsAlimentation from '@/components/TableClubistLogsAlimentation.vue'
import TableClubistLogsTransactions from '@/components/TableClubistLogsTransactions.vue'
import TableClubistLogsEvents from '@/components/TableClubistLogsEvents.vue'
import HistoryExports from './HistoryExports.vue'
import Download from '@/icons/Download.vue'

export default {
  components: {
    TableClubistLogsCashback,
    TableClubistLogsAlimentation,
    TableClubistLogsTransactions,
    TableClubistLogsEvents,
    HistoryExports,
    Download
  },
  props: {
    clubistId: {
      type: String
    },
    clubist: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      isMarketing: 'isMarketing'
    }),
    isMkt () {
      return this.$store.getters.isMarketing
    }
  },
  data () {
    return {
      activeIndex: '1',
      isLoading: false
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      this.activeIndex = key
    }
  }
}
</script>

<style lang="scss" scoped>
.head {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.el-menu-demo {
  display: inline-block
}
.el-menu-item ::v-deep svg {
  stroke: #909399;
  margin-right: 0.4em;
  transition: stroke 0.33s;
}
.el-menu-item:hover ::v-deep svg, .el-menu-item.is-active ::v-deep svg {
  stroke: #303133;
}
</style>
