export default {
  name: 'UploadAttachedFile',

  data () {
    return {
      maxFileSize: 20000, // 20 Mb
      fileName: null,
      fileAccept: 'application/pdf,image/x-png,image/jpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }
  },

  computed: {
    fileInfo () {
      return `Fichiers pdf/jpg/png/word avec une taille inférieure à ${this.maxFileSize / 1000}Mb`
    }
  },

  methods: {
    updateFile (event) {
      const file = event.target.files[0]
      if (file.size > this.maxFileSize * 1000) {
        const weight = (file.size / 1000000)
          .toFixed(0)
          .split('')
          .map((char, index, arr) => (arr.length - index) % 3 === 0 ? ' ' + char : char)
          .join('')
          .trim()

        this.$message.error('Ce fichier est trop lourd (' + weight + 'Mb / ' + this.maxFileSize / 1000 + 'Mb)')
        return
      }

      this.file = file
      this.fileName = file.name

      if (this.inputs) {
        this.inputs.fileName = file.name
      }
    },

    uploadFile (category) {
      let fileInfos
      const options = {
        category,
        file: this.file
      }

      return this.$store.dispatch('file/getUploadFileUrl', options)
        .then(fileData => {
          fileInfos = fileData
          return this.$store.dispatch('file/uploadFile', {
            file: this.file,
            url: fileData.storageUploadUrl
          })
            .then(() => fileInfos)
        })
    }
  }
}
