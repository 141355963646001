<template>
  <div v-loading="isLoading" class="list">
    <template v-if="list.length > 0" class="list">
      <el-table :data="part" @sort-change="onSort" class="table">
        <el-table-column fixed width="140" prop="date" :label="$t('clubistHistory.tables.transactions.column.date')" sortable="custom">
          <template slot-scope="scope">
            {{ dateToDateTimeStr(scope.row.date) }}
          </template>
        </el-table-column>
        <el-table-column width="100" :label="$t('clubistHistory.tables.transactions.column.object')">
          <template slot-scope="scope">
            <div class="nature-row">
              {{ scope.row.nature }}
            </div>
          </template></el-table-column>
        <el-table-column width="185" prop="product" :label="$t('clubistHistory.tables.transactions.column.range')"></el-table-column>
        <el-table-column width="90" align="center" prop="volume" :label="$t('clubistHistory.tables.transactions.column.packing')"></el-table-column>
        <el-table-column width="75" align="center" prop="amount" :label="$t('clubistHistory.tables.transactions.column.price')"></el-table-column>
        <el-table-column width="150" align="center" :label="$t('clubistHistory.tables.transactions.column.station')">
          <template slot-scope="scope">
            {{ scope.row.station.name || scope.row.station.id }}
          </template>
        </el-table-column>
        <el-table-column width="200" :label="$t('tables.column.cardNum')" align="right">
          <template slot-scope="scope">
            <Status :status="scope.row.statusID"/>
            {{ scope.row.clubCard.id }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :current-page="currentPage"
        :total="list.length"
        :page-size="displayCount"
        v-if="!isLoading"
        hide-on-single-page
        @current-change="num => currentPage = num"
        @prev-click="() => currentPage--"
        @next-click="() => currentPage++"
        class="pagination"
      ></el-pagination>
    </template>

    <p v-else-if="!isLoading" class="empty">
      - {{ $t('tables.empty') }} -
    </p>
  </div>
</template>

<script>
import Status from '@/components/Status.vue'

export default {
  components: {
    Status
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    clubistId: {
      type: String
    }
  },
  data () {
    return {
      isLoading: false,
      currentPage: 1,
      displayCount: 9,
      listAll: [],
      modalAmountData: null,
      orderByDateAsc: true,
      modalInfoCashbackRequest: null
    }
  },
  watch: {
    '$route.params.clubistId': 'update',
    listAll: 'onSort'
  },
  computed: {
    list () {
      const list = [...this.listAll]
      const order = this.orderByDateAsc ? 1 : -1
      list.sort((a, b) => a.date < b.date ? order : -order)
      return list
    },
    part () {
      const min = (this.currentPage - 1) * this.displayCount
      const max = min + this.displayCount
      return this.list.slice(min, max)
    }
  },
  created () {
    this.update()
  },
  methods: {
    onSort ({ prop = 'date', order = null }) {
      if (prop === 'date') {
        if (order === 'ascending' || order === null) {
          this.orderByDateAsc = true
        } else if (order === 'descending') {
          this.orderByDateAsc = false
        }
      }
    },
    update () {
      this.currentPage = 1
      this.clubistDisplayCount = 5
      this.listAll = []

      this.isLoading = true
      this.isNotFound = false
      this.$store.dispatch('history/getTransactions', { clubistId: this.clubistId })
        .then(list => {
          this.listAll = list
        })
        .catch(error => {
          this.isNotFound = true
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    dateToDateTimeStr (time) {
      const dateOptions = { year: '2-digit', month: 'numeric', day: 'numeric' }
      const date = new Date(time)
      return date.toLocaleDateString('fr-FR', dateOptions) +
        ' ' + date.toLocaleTimeString('fr-FR')
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  margin-top: 60px;
  min-height: 200px;
  text-align: center;
}
.empty {
  font-size: 14px;
  opacity: 0.3;
  text-align: center;
}
.pagination {
  margin: 20px 0 0;
}
.table {
  max-width: 908px;
}
.nature-row {
  text-transform: capitalize;
}
.modal {
  ::v-deep {
    .el-dialog__body {
      margin-top: -50px;
    }
  }
}
</style>
